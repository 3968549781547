import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '@components/base'

export interface TitleIconProps {
  name: IconName
  style: any
}

interface HighlightsCardProps {
  children?: React.ReactNode
  title: string
  titleContentRight?: string
  titleIconProps?: TitleIconProps
  onMorePress?: () => void
}

export const HighlightsCard = ({
  children,
  title,
  titleContentRight,
  titleIconProps,
  onMorePress,
}: HighlightsCardProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <TouchableOpacity style={styles.container} onPress={onMorePress} accessibilityLabel={title}>
      <View style={styles.contentContainer}>
        <View style={styles.titleContainer}>
          <Text type="title-3">{title}</Text>
          {titleIconProps && <Icon name={titleIconProps.name} style={titleIconProps.style} />}
          <Text type="small" bold style={styles.titleContentRight}>
            {titleContentRight}
          </Text>
          <Icon name="caret-right" style={styles.caret} />
        </View>
        {children}
      </View>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    marginRight: 8,
    borderRadius: 12,
    backgroundColor: 'theme.background',
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    paddingHorizontal: 16,
  },
  contentContainer: {
    paddingTop: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 159,
    paddingBottom: 16,
  },
  titleContentRight: {
    color: 'theme.text.secondary',
    marginLeft: 32,
  },
  caret: {
    marginLeft: 6,
    color: 'theme.text.primary',
    height: 20,
    width: 20,
  },
})
